export const fr = {
  investerraDetails: {
    name: 'Investera AG',
    street: 'Vadianstrasse',
    streetNumber: '50',
    zipCode: '9001',
    city: 'St. Gallen',
    support: {
      email: 'hallo@investerra.ch',
      phone: '+41 71 571 98 00',
      infoEmail: 'info@investerra.ch'
    }
  },
  loader: { heading: "Un petit moment, s'il vous plaît" },
  header: {
    navigation: {
      calculateOffer: 'Calculer une offre',
      submitOffer: 'Télécharger une offre',
      ['my_profile']: 'Mon compte'
    }
  },
  footer: {
    description:
      'Investerra te propose de manière simple et sûre des conditions attractives pour le financement de ta solution de transition énergétique.',
    contact: {
      label: 'Contact',
      number: '+41 71 571 98 00',
      webPage: 'info@investerra.ch',
      contactUs: 'Contact'
    },
    info: {
      aboutInvestera: "À propos d'Investerra",
      contact: 'Contactez-nous',
      becomePartner: 'Devenir partenaire spécialisé',
      faq: 'FAQ',
      insurance: 'Assurance'
    },
    rules: {
      impresion: 'Mentions légales',
      dataProtection: 'Protection des données',
      termsOfUse: "Conditions d'utilisation"
    },
    copyright: '©2024, Investerra SA'
  },
  financalOfferPreview: {
    financingOffer: 'Offre de financement',
    tooltipFinancingOffer:
      'Notre offre pour ton financement. Les informations sont basées sur tes indications et sur des estimations basées sur notre expérience.',
    indicative: {
      label: 'Indicateur',
      tooltip: 'Prix total indicatif du projet - coûts que tu dois prévoir pour le projet.'
    },
    objectPrice: {
      label: "Prix de l'objet",
      tooltip:
        'Prix total du projet - coûts que tu dois prévoir pour le projet, y compris les fonds de soutien'
    },
    loan: {
      label: 'Montant du crédit',
      tooltip: 'Le montant du crédit indique le montant du financement nécessaire. '
    },
    baseFee: {
      label: 'Frais de base minimum',
      tooltip:
        'La commission de base est due une seule fois et doit être considérée comme un forfait de services. Elle est plafonnée à 4% du montant du crédit. '
    },
    effectiveAnualIntrestRate: {
      label: "Taux d'intérêt annuel",
      tooltip: "Ton taux d'intérêt individuel pour le financement de ton placement. "
    },
    saleOfElectricityByInvesterra: {
      label: "Vente d'électricité par Investerra",
      tooltip:
        "Profite du prix actuel de l'électricité. Nous vendons ton surplus d'énergie directement sur le marché aux prix actuels de l'énergie. ",
      value: ' Ct. 10.5 kWh'
    },

    co2Potential: {
      label: 'Économies potentielles de CO2',
      tooltip:
        'En installant des panneaux photovoltaïques, tu contribues à la réduction des émissions de CO2. Nous mettons à ta disposition une estimation approximative de tes économies.'
    },
    anualSavings: {
      label: 'Économies annuelles',
      tooltip: "Economies réalisées grâce à l'autoconsommation de la puissance produite."
    },
    feedInTariff: {
      label: 'Rétribution du courant injecté',
      tooltip:
        "Ce montant te sera versé par le fournisseur d'énergie pour ton excédent d'énergie. C'est-à-dire tout ce que tu n'as pas pu consommer toi-même."
    },
    financialSupport: {
      label: 'Promotion',
      tooltip:
        "Rémunérations uniques pour les petites installations photovoltaïques. Il s'agit ici de l'aide fédérale. Selon le lieu de résidence, des subventions peuvent encore être possibles au niveau cantonal et communal."
    }
  },

  /*** DO NOT TRANSLATE BEGIN ***/
  offerAndFinancingText: {
    calculateOfferNow: 'Jetzt Offerte berechnen',
    calculateOfferHeadline: 'Offerte rechnen',
    energyTransitionTopicQuestion:
      'Du hast dich noch nicht mit dem Thema Energiewende beschäftigt?',
    energyTransitionTopicAnswer: 'Dann zeigen wir dir dein Potenzial über unseren Rechner auf.',
    solarCalculatorDescription:
      'Basierend auf dem Standort deiner Immobilie kann unser Solarrechner bereits einschätzen, ob sich eine oder mehrere Dachflächen für eine Solaranlage eignen. Bei Bedarf helfen wir dir anschliessend bei der Suche nach dem geeigneten Installationspartner.'
  },
  uploadOfferHome: {
    uploadOfferAndCalulate: 'Jetzt Offerte hochladen & Finanzierung berechnen',
    uploadOfferHeadline: 'Offerte hochladen',
    alreadyHaveOffer:
      'Du hast dich schon mit dem Thema Energiewende beschäftigt und bereits eine Offerte vom Fachmann?',
    sugestion: 'Dann können wir dir in wenigen Schritten ein Finanzierungsangebot machen.',
    uploadOffer:
      'Lade einfache die vorhandenen Offerte als PDF hoch und wir berechnen dir direkt und unkompliziert deine persönliche monatliche Finanzierungsrate, die du ganz an deine Bedürfnisse anpassen kannst.'
  },
  /*** DO NOT TRANSLATE END ***/

  module: {
    label: 'Module',
    labelPowerFeed: "Injection d'électricité dans le réseau",
    selectAdditionalModules: 'Choisis encore des modules supplémentaires si nécessaire:',
    selectPowerFeedModule:
      "Investerra achète la partie de ton électricité photovoltaïque que tu n'utilises pas toi-même. Avec l'injection des excédents, tu obtiens des prix intéressants pour l'électricité injectée dans le réseau.",
    types: {
      E_CHARGING_STATION: 'Station de recharge électrique',
      HEAT_PUMP: 'Pompe à chaleur',
      STORAGE_SOLUTION: 'Solution de stockage',
      INVESTERRA_ELECTRICITY_SALES: "Vente d'électricité par Investerra"
    },
    tooltips: {
      E_CHARGING_STATION: 'Station de recharge pour véhicule électrique',
      HEAT_PUMP:
        "Solution de chauffage qui absorbe la chaleur de l'environnement (air, sol ou nappe phréatique) et l'amène à un niveau de température plus élevé.",
      STORAGE_SOLUTION: "Stockage de l'énergie électrique.",
      INVESTERRA_ELECTRICITY_SALES:
        "Profite du prix actuel de l'électricité. Nous vendons ton surplus d'énergie directement sur le marché aux prix actuels de l'énergie. "
    },
    tooltip:
      "Choisis les produits souhaités. Tu peux combiner les modules à ta guise. Attention, il s'agit de prix indicatifs.",
    tooltipPowerFeed:
      "Profite du prix actuel de l'électricité. Nous vendons ton surplus d'énergie directement sur le marché spot aux prix actuels de l'énergie. "
  },
  solarSystem: {
    modalTitle: {
      lowSustainability: 'Nous sommes désolés!',
      selectPartner: 'Choisir un partenaire'
    },
    selectPartnerContent: {
      automaticSelection: 'Sélection automatique',
      noPartnersWithThisName: "Le partenaire n'a pas pu être trouvé",
      selectUpToNumberPartners:
        'Cette fonctionnalité est actuellement en cours de développement. Veuillez choisir «Investerra». Nous vous aidons à trouver le bon prestataire.',
      showMorePartners: 'afficher plus de partenaires'
    },
    lowSustainabilityModal: {
      text: 'Nous ne trouvons pas de toit approprié pour une installation photovoltaïque à cette adresse. Pour toute question, contactez-nous au'
    },
    headingDescription:
      "En fonction de ta situation géographique, notre calculateur solaire peut estimer si une ou plusieurs surfaces de toit se prêtent à une installation solaire. Par l'intermédiaire de nos partenaires spécialisés, tu peux demander directement et sans engagement une offre pour ton projet et solliciter ton financement individuel.",
    solarPotential: {
      label: 'Potentiel solaire',
      unit: 'kWh / année',
      tooltip:
        "Le potentiel solaire te permet de savoir rapidement si et dans quelle mesure la surface de ton toit est adaptée à l'énergie solaire."
    },
    consumptionPotential: {
      label: "Potentiel d'autoconsommation",
      prefix: 'environ',
      tooltip:
        "Le potentiel d'autoconsommation détermine la quantité d'énergie produite qui peut être consommée dans le propre ménage."
    },
    energyClass: {
      label: 'Classe énergétique',
      tooltip:
        "Son statut actuel ressemble aujourd'hui à peu près à l'image suivante et va globalement de la meilleure zone possible vert foncé/vert à la zone rouge vraiment mauvaise. Les zones en jaunes sont encore considérées comme intéressantes."
    },
    sustainability: {
      label: 'Aptitude',
      LOW: 'Faible',
      MEDIUM: 'Moyens',
      GOOD: 'Bon',
      VERY_GOOD: 'Très bon',
      EXCELLENT: 'Excellent',
      NA: 'Aucune information',
      tooltip:
        "L'adéquation est représentée par un modèle d'évaluation des performances à quatre niveaux. (Très bon, Bon, Suffisant à Inapproprié)"
    },
    financingPlan: {
      label: 'Plan de financement',
      tooltip: 'Tu peux personnaliser ton modèle de financement ou utiliser notre proposition.',
      duration: 'Durée de validité',
      deposit: 'Acompte',
      indicativePrice: 'Prix indicatif par mois',
      tooltipIndicativePrice:
        "Le prix indicatif par mois se compose de : Montant du crédit, taux annuel effectif global, durée (période de remboursement), L'octroi de crédit est interdit s'il entraîne un surendettement (art. 3 LCD).",
      prohibitLending:
        "L'octroi de crédit est interdit s'il entraîne un surendettement (art. 3 LCD)."
    },
    pvSystemOutput: {
      label: "Puissance de l'installation PV",
      tooltip: "Puissance maximale de l'installation en kWp"
    }
  },
  breadCrumbs: {
    home: 'Home',
    'solar-system': "Calculer l'offre",
    'upload-offer': 'Télécharger une offre',
    'my-profile': 'Mon compte',
    'personal-data': 'Données personnelles',
    offer: 'Offres',
    edit: "Modifier l'offre",
    projects: 'Projets',
    'platform-terms': 'Conditions de la plateforme',
    clients: 'Aperçu des clients',
    invoices: 'Factures',
    details: 'Détails',
    inbox: 'Ma boîte de réception',
    partners: 'Aperçu des partenaires',
    account: 'Mon compte',
    offers: 'Aperçu du projet Installateur'
  },
  auth: {
    registration: 'Inscription',
    register: "S'inscrire",
    registerHere: "S'inscrire ici",
    createProfileText: 'Pour faire une demande finale de financement, vous devez créer un profil.',
    name: 'Prénom et nom',
    password: 'Mot de passe',
    or: 'ou',
    alreadyHaveAProfile: 'Déjà inscrit?',
    dontHaveAProfile: 'Pas encore de compte?',
    login: 'Se connecter',
    loginGoogle: 'Se connecter à Google',
    loginApple: 'Se connecter avec Apple',
    loginHere: 'Se connecter ici',
    signout: 'Se déconnecter',
    thankForRegistration: 'Merci pour votre inscription.',
    toActivateAccount:
      "Ton compte est créé. Pour activer le compte et s'assurer qu'il s'agit bien de toi, une confirmation unique de ton adresse e-mail est nécessaire.",
    confirmYourEmail: "Pour ce faire, clique sur le lien de confirmation dans l'e-mail.",
    verifyEmail: 'Vérification des e-mails',
    checkSpamSignUp:
      "Tu ne trouves pas d'e-mail dans ta boîte de réception? Vérifie ton dossier spam.",
    contactUs:
      'Tu as des problèmes pour créer ou accéder à ton compte?  <contactUs>Contacte-nous.</contactUs>',
    emailAndPassword: 'E-mail et mot de passe',
    forgotPassword: 'Mot de passe oublié',
    passwordReset: {
      title: 'Réinitialiser le mot de passe',
      pleaseEnterEmail:
        "Saisis l'adresse e-mail que tu utilises chez Investerra. Tu recevras un e-mail avec des instructions. Si tu ne reçois pas de message dans ta boîte de réception dans les prochaines minutes, vérifie ton dossier spam.",
      clickOnTheLink: "Cliquez sur le lien dans l'e-mail pour réinitialiser le mot de passe.",
      sendInstructions: 'Envoyer des instructions de réinitialisation',
      emailSent:
        "L'e-mail contenant les instructions pour réinitialiser le mot de passe est en route vers {{email}}.",
      newPassword: 'Nouveau mot de passe',
      repeatPassword: 'Confirmer le mot de passe',
      passwordMatching: 'Les mots de passe doivent correspondre',
      linkExpired: 'Le lien de réinitialisation a expiré',
      passwordChanged: 'Votre mot de passe a été modifié avec succès !',
      checkSpam:
        'Si tu ne trouves pas de message dans ta boîte de réception dans les prochaines minutes, vérifie ton dossier spam.'
    },
    termsAndConditions:
      "En m'inscrivant, j'accepte les <termsLink>conditions générales d'utilisation</termsLink> et la <privacyLink>déclaration de protection des données</privacyLink>",
    errors: {
      nameCanContainOnlyLetters: 'Le nom ne peut contenir que des lettres',
      pleaseEnterValidEmail: 'Veuillez saisir une adresse e-mail valide',
      emailAllreadyInUse: 'E-mail déjà utilisé',
      passwordValidationError:
        'Le mot de passe doit comporter 8 caractères (1 lettre majuscule, 1 chiffre, 1 caractère spécial).',
      pleaseTryAgain:
        "Oh, quelque chose n'a pas fonctionné. Veuillez réessayer, une erreur est survenue. Veuillez réessayer plus tard.",
      loginError: "L'adresse e-mail ou le mot de passe n'est pas correct",
      requiredField: 'Ce champ est obligatoire',
      incorrectInput: 'Vérifie ton adresse e-mail et ton mot de passe',
      yourAccountHasBeenCreatedWith:
        'Ton compte a été créé avec {{method}}. Veuillez changer votre mot de passe une fois.',
      emailNotFound: 'E-mail introuvable.',
      emailRegistered: 'Cet e-mail est enregistré via {{method}}',
      invalidPhoneNumber: "+41799999999 - Saisie de l'indicatif du pays (+41 pour la Suisse).",
      wrongPassword: 'Le mot de passe saisi est incorrect',
      invalidVerificationCode: 'Code de vérification non valide',
      confirmPasswordError: 'Les mots de passe ne correspondent pas!',
      verificationLinkExpired: 'Votre lien de vérification a expiré.',
      invalidVerificationLink: "Votre lien de vérification n'est pas valide ou a déjà été utilisé.",
      partnerAlreadyRegistered: {
        heading: 'Cette entreprise est déjà enregistrée',
        text: "Cette entreprise est déjà enregistrée chez Investerra. Veuillez vous adresser à votre administration interne pour obtenir les données de connexion. Avec les données de connexion, tu peux t'inscrire <contactLink> ici</contactLink>."
      },
      somethingWentWrongRegistration:
        "Nous sommes désolés – un problème est survenu lors de l'inscription et de la connexion. Veuillez nous contacter directement à l'adresse <emailLink>hallo@investerra.ch</emailLink>.",
      loginFailed: "Échec de l'inscription"
    }
  },
  requestOfferTitle: {
    selectPartner: 'Choisir un partenaire',
    offerRequestedNotification: "Demande d'offre",
    registration: 'Inscription'
  },
  emailConfirmation: {
    accountVerified: 'Compte vérifié',
    verificationSuccessful: 'La vérification du compte a été effectuée avec succès.',
    continueToFunding: 'Aller à la demande de financement',
    offerAvailableGetsNotification:
      "Tu seras informé(e) par e-mail dès que l'offre sera disponible.",
    dataSentToPartners:
      'Ta demande nous est parvenue. Tu recevras un e-mail dès que ton offre sera prête.',
    checkYourSpamOffer:
      "Si tu ne trouves pas d'e-mail dans ta boîte de réception dans les prochains jours, vérifie ton dossier spam.",
    financingRequestSent:
      "Ton offre a été transmise à Investerra. L'examen de ton offre de financement a commencé.",
    willBeNotifiedInTwoDays:
      'Tu seras averti(e) dans les deux jours ouvrables suivants dès que ton offre sera disponible.'
  },
  uploadOffer: {
    title: 'Télécharger une offre',
    description:
      "Télécharge ici l'offre de l'installateur pour ton installation solaire afin d'obtenir une proposition de financement.",
    dragFileHere:
      'Il suffit de faire glisser le fichier dans cette zone ou de le télécharger en cliquant sur le lien ci-dessous.',
    qr: {
      title: "Scanner l'offre maintenant en mobilité",
      description: {
        doNotHaveOfferOficial: "Tu n'as pas encore reçu ton offre sous forme numérique?",
        scanQrCode:
          'Pas de problème - il suffit de scanner le code QR avec ton téléphone portable et de suivre les instructions.'
      }
    },
    pending: {
      title: 'Les données sont transmises...',
      description:
        "L'offre est téléchargée et traitée. Votre offre de financement est générée. Cela peut prendre quelques minutes."
    },
    success: {
      title: 'Données transmises avec succès',
      description:
        'Tes données ont été téléchargées avec succès. Veuillez vérifier les données saisies',
      troubleUploading:
        'Difficultés lors du téléchargement ? <contactLink>Contactez-nous!</contactLink>',
      buttonLabel: 'Continuer'
    },
    error: {
      title: "Le document n'a pas pu être traité!",
      description:
        "Oups, quelque chose n'a malheureusement pas fonctionné. Un problème est survenu lors du téléchargement - le document n'a pas pu être traité. Merci de télécharger à nouveau le document ou de scanner ton offre directement avec ton appareil mobile en scannant le code QR. Merci beaucoup."
    },
    errorType: {
      title: 'Merci beaucoup',
      description: 'Notez que pour le moment, seul un téléchargement PDF fonctionne',
      buttonLabel: 'Essayer à nouveau'
    },
    searchHereForAddress: "Rechercher l'adresse du bien"
  },
  partnerOfferView: {
    projectDetails: 'Détails du projet',
    mandatoryFieldsShouldBePopulated: 'Tous les champs obligatoires doivent être remplis.'
  },
  currency: {
    chf: 'CHF'
  },
  uploadOfferEdit: {
    projectDetails: 'Détails du projet',
    allFieldsRequired:
      "Les champs marqués d'un * sont obligatoires. Merci de les remplir pour recevoir ton offre.",
    thankYou: 'Merci beaucoup',
    maintenance: "Conseil d'entretien",
    maintenanceText:
      "La lecture automatique sera bientôt activée, merci de remplir les champs avec un * à la main d'ici là."
  },
  editPartner: {
    partnerDetails: 'Détails sur le partenaire',
    partnerContactName: 'Partenaire Prénom et nom',
    companyName: "Nom de l'entreprise",
    companyAddress: "Adresse de l'entreprise",
    companyEmail: "E-mail de l'entreprise"
  },
  offer: {
    price: "Prix de l'offre",
    pvSystem: 'Installation PV',
    plantPower: "Puissance de l'installation",
    issuanceDate: "Date d'édition",
    validThru: "Date d'expiration",
    deliveryDate: 'Date de livraison',
    contactPerson: 'Personne de contact',
    partnerCompany: 'Société'
  },
  projectDetails: {
    label: 'Détails du projet',
    status: 'Statut du projet',
    statusChangeSuccess: 'Le statut du projet a été mis à jour!',
    uploadReport: "Télécharger le rapport d'acceptation"
  },
  partnerFinance: { heading: 'Finances & contrats', tabs: { partnerInvoices: 'Factures' } },
  partnerInvoices: {
    table: {
      project: {
        id: 'Projet #',
        building: { address: { street: 'Adresse du projet' } },
        owner: { firstName: 'Nom du client' }
      },
      projectStatus: 'Statut du projet',
      amount: 'Montant de la facture',
      timestamp: 'Date de téléchargement',
      transactionStatus: 'Statut de la facture'
    },
    transactionStatuses: 'Statut de la facture',
    searchPlaceholder: 'Recherche par client ou par adresse de projet'
  },
  projectOverview: {
    tabs: { projectOverview: 'Aperçu du projet', contracts: 'Contrats', invoices: 'Factures' },
    snackbar: {
      heading: "Nouvelle offre d'installation",
      text: 'Tu as reçu une ou plusieurs nouvelles offres'
    },
    contractsTab: {
      contractDetails: 'Détails des contrats',
      procesDescription:
        'Veuillez télécharger le(s) contrat(s), l(es) imprimer, le(s) signer et l(es) envoyer à Investerra par courrier :',
      processProblem: 'Des problèmes avec le contrat ? Veuillez contacter Investerra ici :',
      uploadSignedPdf: 'Télécharger le contrat signé',
      downloadInPdf: 'Télécharger le PDF',
      showPdf: 'Voir le PDF',
      uploadProofOfInsurance: "Télécharger l'attestation d'assurance",
      contracts: {
        CREDIT_LINE: 'Contrat de crédit',
        ELECTRICITY_TRADING: "Contrat de revente d'électricité",
        ORDER_CONFIRMATION: 'Confirmation de commande'
      },
      orderConfirmationDescription:
        'Veuillez télécharger ici la confirmation de commande du projet transmise par votre installateur.',
      projectInsurance: 'Assurance de projet',
      supplementBuildingInsurance: "Complément d'assurance bâtiment",
      descriptionInstruction:
        "Dans les contrats, Investerra oblige le preneur de crédit à conclure une solution d'assurance pour le placement. Lire les détails de l'assurance <contactLink> ici.</contactLink>",
      descriptionChoice:
        "Si tu choisis ta propre assurance, tu dois télécharger une attestation d'assurance avant le versement du crédit.",
      useYourInsurance: "Je souhaite utiliser ma propre solution d'assurance"
    },
    invoices: {
      creditText:
        'Ici apparaît ta facture pour le remboursement du crédit. Veuillez utiliser cette facture pour configurer votre LSV ou eBill auprès de votre banque.'
    }
  },
  genericErrors: {
    notEmpty: 'Le champ ne doit pas être vide.',
    positiveNumber: 'La valeur doit être un nombre positif.',
    entereValidPostcode: 'Veuillez saisir un code postal valide.',
    enterValidIBAN: 'Veuillez entrer un IBAN valide',
    mustBe18YearsOld: 'Du musst mindestens 18 Jahre alt sein.'
  },
  partnerProjectsOverview: {
    heading: 'Aperçu du projet Installateur',
    searchByNameEmail: 'Recherche par nom, e-mail ou adresse',
    invoiceAmount: 'Montant de la facture',
    creditPrice: 'Prix du crédit',
    table: {
      heading: {
        id: 'Projet #',
        project: {
          owner: { firstName: 'Prénom / Nom de famille', email: 'Email' },
          building: { address: { street: 'Adresse du projet' } }
        },
        sentTimestamp: 'Date Demande',
        offeredPrice: "Prix total du crédit d'ouvrage",
        status: 'Statut'
      }
    }
  },
  units: {
    meterSqared: 'm²',
    kg: 'kg',
    kilowatt: 'kW',
    kilowattPerformance: 'kWc',
    rpKilowatt: 'Ct. / kWh'
  },
  offers: {
    heading: "Offres d'installations",
    finalMonthlyRate: 'Mensualité finale',
    installerDetails: "Détails de l'installateur",
    tooltipFinalMonthlyRate:
      "La mensualité finale se compose de : Montant du crédit, taux annuel effectif global, durée (période de remboursement), L'octroi de crédit est interdit s'il entraîne un surendettement (art. 3 LCD).",
    tab: { heading: 'Offres' },
    snackbar: {
      heading: {
        CREATED: 'Offres de partenaires disponibles',
        PENDING: 'Offre de partenariat en suspens',
        ACCEPTED: 'Offre de partenariat acceptée'
      },
      text: {
        PENDING:
          "Cette offre est encore en cours de traitement chez le partenaire d'installation. Nous t'informerons dès que l'offre sera soumise.",
        CREATED: "Nouvelle offre d'installation disponible",
        ACCEPTED: "Offre d'installation acceptée"
      }
    },
    offerDetails: {
      heading: "Offre d'installation",
      offerDate: "Date de l'offre",
      address: 'Adresse',
      downloadAcceptanceReport: 'Télécharger le rapport de réception PDF',
      subsidy: {
        label: 'Promotion'
      },
      pvSystem: {
        label: 'Installation PV',
        tooltip: { heading: 'Installation PV', text: "Surface utilisée pour l'installation." }
      },
      plantPower: {
        label: "Puissance de l'installation",
        tooltip: {
          heading: "Puissance de l'installation",
          text: "Puissance maximale de l'installation en kWp."
        }
      },
      validThruDate: {
        label: "Date d'expiration",
        tooltip: { heading: "Date d'expiration", text: "Validité de l'offre." }
      },
      instructionNote: {
        pleaseNote: 'Veuillez noter que:',
        instruction: 'Afin de sauvegarder les modifications, un devis doit être téléchargé.'
      },
      installationDate: {
        label: "Date d'installation",
        tooltip: {
          heading: "Date d'installation",
          text: "Date prévue d'installation de l'équipement."
        }
      },
      offerPrice: {
        label: "Prix de l'offre",
        tooltip: { heading: "Prix de l'offre", text: "Prix offert pour l'installation." }
      },
      instalationPartner: {
        tooltip: {
          text: "Texte du partenaire d'installation",
          heading: 'Instalation partner heading'
        }
      }
    },
    financialOfferPreview: {
      extrasAndModules: 'Modules sélectionnés & extras',
      extrasAndModulesTooltip:
        'Compilation des modules supplémentaires et des prestations complémentaires souhaitées.'
    },
    keyFigures: { heading: 'Chiffres clés' },
    modulePreview: { heading: 'Modules sélectionnés' },
    image: { heading: 'Détails du projet' },
    offerNumber: "Numéro de l'offre",
    description: 'Description',
    additionalModules: 'Modules complémentaires',
    modal: {
      deleteProject: 'Supprimer un projet',
      register: 'Inscription',
      creditVolumeRestriction: 'Crédits supérieurs à {{maxLoanAmount}} CHF'
    },
    contract: {
      description:
        "Important : pour conclure l'offre d'installation, veuillez télécharger le PDF, le signer et le retourner au partenaire d'installation.",
      buttonText: 'Télécharger le PDF',
      orderConfirmationDownload: 'Télécharger la confirmation de commande'
    },
    loanRequested: { success: 'Demande de crédit déposée' },
    loanRequestException: {
      contactDirectly:
        'Pour les crédits importants de plus de CHF {{maxLoanAmount}}, nous te prions de nous contacter directement. Pour ces montants, des vérifications plus détaillées sont effectuées.',
      contactUsHere: 'Contacte-nous ici'
    }
  },
  myProfile: {
    title: 'Mon compte',
    cards: {
      personalData: {
        title: 'Données personnelles',
        description: 'Consulter et modifier les données personnelles enregistrées.',
        buttonText: 'Modifier'
      },
      myProjects: {
        title: 'Mes projets',
        description: "Tous les projets en un coup d'œil.",
        buttonText: 'Détails'
      },
      myNews: {
        title: 'Ma boîte de réception',
        description: "Afficher les nouvelles d'Investerra et de ses partenaires.",
        buttonText: 'Ouvrir'
      },
      support: {
        title: 'Aide & Support',
        description: "des solutions aux problèmes courants ou l'aide d'un agent d'assistance.",
        buttonText: 'Contact'
      }
    },
    personalData: {
      title: 'Données personnelles',
      address: 'Adresse du domicile',
      profileUpdated: 'Votre profil a été mis à jour avec succès.',
      kyc: {
        identification: 'Identification',
        startIdentification: "Commencer l'identification maintenant",
        status: {
          title: 'Statut',
          successful: 'Réussir',
          pending: 'En attente',
          unsuccessful: 'Échec'
        },
        description: 'Pour obtenir un crédit, une identification (KYC) est nécessaire.'
      },
      deleteUser: {
        title: "Suppression de l'utilisateur",
        buttonText: 'Supprimer un utilisateur',
        text: 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
        toastMessage: 'Suppression réussie'
      },
      deleteAccount: 'Pour supprimer ton compte, contacte-nous <contactLink>ici</contactLink>.'
    },
    security: {
      title: 'Sécurité'
    },
    changePassword: {
      title: 'Modifier le mot de passe',
      oldPassword: 'Ancien mot de passe',
      newPassword: 'Nouveau mot de passe',
      success: 'Mot de passe actualisé'
    },
    twoFactorAuth: {
      title: 'Authentification à deux facteurs',
      description:
        "Vous pouvez renforcer votre sécurité en activant l'authentification à deux facteurs en option. Lorsqu'elle est activée, vous devez saisir un code unique via une application d'authentification à chaque connexion.",
      label: "Activer l'authentification à deux facteurs",
      changePasswordHeading: 'Modifier le mot de passe',
      changePasswordText:
        'Si tu as oublié ton mot de passe, tu peux le changer en cliquant sur le bouton.'
    },
    bankDetails: {
      title: 'Coordonnées bancaires',
      bankName: 'Nom de la banque',
      accountHolder: 'Titulaire du compte',
      address: 'Adresse',
      bic: 'BIC',
      updateSuccess: 'Vos modifications ont été enregistrées avec succès'
    },
    myProjects: {
      labels: {
        project: 'Projet',
        created: 'Créé',
        status: 'Statut',
        showDetails: 'Afficher les détails',
        search: 'Recherche par adresse ou numéro de projet'
      }
    }
  },
  inbox: { title: 'Ma boîte de réception', empty: 'Votre boîte de réception est vide.' },
  admin: {
    projects: {
      title: 'Aperçu des projets',
      table: {
        heading: {
          id: 'Projet #',
          creationTime: 'Date',
          owner: { firstName: 'Nom du client' },
          building: { address: { street: 'Adresse du projet' } },
          offer: { partner: { name: "Partenaire d'installation" } },
          status: 'Statut',
          lastLogAuthor: 'Dernière modification'
        }
      },
      labels: {
        approveCheck: 'Examen Editer',
        projectDetails: 'Détails des projets',
        lastChange: 'Dernière modification',
        customerName: 'Nom du client',
        projectAddress: 'Adresse du projet',
        requestLoanAmount: 'Montant forfaitaire annuel'
      }
    },
    projectDetails: {
      project: 'Projet',
      tabs: {
        projectOverview: 'Aperçu du projet',
        projectReview: 'Vérification du projet',
        contracts: 'Contrats',
        invoices: 'Factures'
      },
      projectReview: {
        modalTitles: {
          kremo: 'Kremo',
          experian: 'Détails: {{experianStatus}}',
          dataConfirmation: 'Approuver la demande?',
          complianceCheck: 'Contrôle de conformité (PEP)',
          zek: 'ZEK',
          financialDataValuation: 'Données financières Évaluation',
          personalDetails: 'Détails personnels',
          creditOffer: 'Offre de crédit',
          kycPersonalDetails: 'Détails personnels KYC',
          comment: 'Commentaires'
        },
        instructions: {
          heading: "Soumission d'examen",
          processingNote: {
            heading: 'Note de traitement:',
            text: "Vous pouvez déposer une nouvelle soumission d'examen ici. Veuillez télécharger tous les documents nécessaires dans la zone de droite. Pour finaliser le téléchargement des documents et le commentaire, terminez l'examen en cliquant sur « Accepter » ou « Refuser »."
          },
          pleaseNote: {
            heading: 'Veuillez noter :',
            text: 'Si la page est rechargée ou fermée, les commentaires et fichiers déjà déposés seront supprimés.'
          }
        },
        newComment: 'Nouveau commentaire',
        documentUpload: 'Téléchargement de documents',
        userProjectData: {
          fields: {
            kycPersonalDetails: 'KYC',
            experian: 'Experian',
            kremo: 'Kremo (Montant en suspens avant la demande)',
            complianceCheck: 'Contrôle de conformité',
            zek: 'ZEK',
            financialDataValuation: 'Données financières Évaluation',
            creditCheck: 'Intrum Credit Check',
            uploadedFiles: 'Fichiers téléchargés'
          },
          questions: {
            installerChecked: "L'installateur a-t-il été contrôlé ?",
            confirmation: "Je confirme que j'ai effectué tous les thèmes d'une révision générale !"
          }
        },
        kremoTables: {
          description:
            'En Suisse, “KREMO” fait référence au système de “Kredite Monitoring”, un outil de surveillance du crédit utilisé par les institutions financières et les entreprises pour évaluer la solvabilité des individus et des entreprises. Il aide à évaluer les risques de crédit en fournissant des rapports détaillés sur l’historique financier d’une personne ou d’une entreprise, y compris les dettes en souffrance, les comportements de paiement et d’autres informations financières pertinentes. Ce système est essentiel pour prendre des décisions de prêt et des décisions commerciales éclairées.',
          applicantTable: {
            heading: {
              applicantExpenditures: 'Dépenses du demandeur',
              partnerExpenditures: 'Dépenses du partenaire'
            },
            data: {
              exemptAmount: 'Montant exempté',
              taxAtSource: 'Impôt à la source',
              socialCharges: 'Charges sociales',
              externalFoodBoardingDeduction: 'Déduction pour repas externes',
              increasedFoodIntakeDeduction: 'Déduction pour consommation accrue de nourriture',
              increasedClothingRequirementDeduction: 'Déduction pour besoins accrus en vêtements',
              transportationCost: 'Coût de transport',
              openCreditAmount: 'Montant de crédit ouvert',
              openLeasingRateAmount: 'Montant de taux de leasing ouvert',
              exemptAmout: 'Montant exempté'
            }
          },
          houseHoldTable: {
            heading: {
              houseHoldExpenditures: 'Dépenses du ménage'
            },
            data: {
              healthInsuranceFee: "Frais d'assurance maladie",
              subsistenceMinimum: 'Minimum de subsistance'
            }
          }
        },
        dataConfirmation: {
          text: "Êtes-vous sûr d'avoir vérifié toutes les données nécessaires et d'être convaincu de votre décision ? Si vous cliquez sur 'Confirmer', votre décision sera consignée."
        },
        complianceCheck: {
          pep: 'Personne Politiquement Exposée (PPE)',
          sanctionList: 'Liste des Sanctions',
          watchList: 'Liste de Surveillance',
          table: {
            heading: {
              id: 'ID',
              firstName: 'Prénom',
              fullName: 'Nom Complet',
              lastName: 'Nom de Famille',
              dateOfBirth: 'Date de Naissance',
              country: 'Pays',
              nameOfList: 'Nom de la liste'
            }
          }
        },
        zek: {
          description:
            "ZEK (Zentralstelle für Kreditinformation) est un bureau de crédit suisse qui collecte et gère des informations liées au crédit, aidant les prêteurs à évaluer la solvabilité des individus. Il joue un rôle crucial dans la facilitation des pratiques de prêt responsables en fournissant un aperçu complet de l'historique de crédit d'une personne et de ses obligations financières.",
          zekScore: 'Score ZEK',
          table: {
            heading: {
              clientId: 'Identifiant du Client',
              zek: 'ZEK',
              firstName: 'Prénom',
              lastName: 'Nom de Famille',
              gender: 'Genre',
              dateOfBirth: 'Date de Naissance',
              fullAddress: 'Adresse Complète',
              nationality: 'Nationalité',
              personStatus: 'Statut de la Personne',
              hitScore: 'Score de Correspondance'
            }
          }
        },
        financialValuation: {
          table: {
            heading: { applicant: 'Demandeur', partner: 'Partenaire' },
            data: {
              monthlyHousingCosts: 'Coûts Mensuels du Logement',
              monthlyExpensesAlimony: 'Dépenses Mensuelles de Pension Alimentaire',
              monthlyEducationCosts: "Coûts Mensuels d'Éducation",
              monthlyProfessionalOrganizationFee: "Frais Mensuels d'Organisation Professionnelle",
              monthlyOtherFixExpenses: 'Autres Dépenses Fixes Mensuelles',
              monthlyOneTimeExpenses: 'Dépenses Exceptionnelles Mensuelles',
              totalMonthlyIncomeGrossAmount12: 'Montant Total des Revenus Mensuels Bruts 12',
              monthlyIncomeGrossAmountMain12: 'Montant Mensuel des Revenus Bruts Principaux 12',
              disposableMonthlyIncome: 'Revenu Mensuel Disponible',
              monthlySurplusInstallmentRatio: 'Ratio du Surplus Mensuel pour les Versements'
            }
          }
        },
        kycPersonalDetailsTable: {
          table: {
            heading: { title: 'Titre', data: 'Données' },
            data: {
              firstName: 'Prénom',
              lastName: 'Nom de Famille',
              gender: 'Genre',
              maritalStatus: 'État Civil',
              nationality: 'Nationalité',
              householdType: 'Type de Ménage',
              householdMonthlyInsuranceFee: "Frais d'Assurance Mensuels du Ménage",
              street: 'Rue',
              houseNumber: 'Numéro de Maison',
              postalCode: 'Code Postal',
              city: 'Ville',
              canton: 'Canton',
              country: 'Pays',
              residenceType: 'Type de Résidence'
            }
          }
        },
        personalDetailsTable: {
          table: {
            heading: { title: 'Titre', data: 'Données' },
            data: {
              firstName: 'Prénom',
              lastName: 'Nom de Famille',
              email: 'Email',
              phoneNumber: 'Numéro de Téléphone',
              street: 'Rue',
              houseNumber: 'Numéro de Maison',
              zipCode: 'Code Postal',
              town: 'Ville',
              bank: 'Nom de la banque',
              iban: 'IBAN'
            }
          }
        },
        creditOfferTable: {
          table: {
            heading: { title: 'Titre', data: 'Données' },
            data: {
              loanAmount: 'Montant Demandé',
              loanTerm: 'Durée Demandée',
              monthlyRate: 'Versement Mensuel Demandé',
              decliningBalance: 'Solde Débiteur',
              deposit: 'Paiement Initial',
              interestRate: "Taux d'Intérêt Nominal",
              contractStartDate: 'Date de Début du Contrat'
            }
          }
        },
        commentPreview: {
          previousComment: 'Commentaire Précédent',
          nextComment: 'Commentaire Suivant'
        }
      },
      title: 'Détails du projet',
      history: 'Historique',
      review: 'Vérification',
      course: 'Historique & commentaires',
      log: 'Documents',
      completeCheck: 'Terminer la vérification',
      residentalAddress: 'Adresse du domicile',
      offerRequested: "Demande d'offre",
      adminComment: 'Remarques Admin',
      commentSaved: 'Commentaire sauvegardé',
      contracts: {
        contractsList: 'Contrats',
        uploadSignedContracts: 'Télécharger ici les contrats finaux signés par Investerra.',
        uploadFinalContract: 'Télécharger le contrat final',
        creditContract: 'Contrat de crédit',
        elictricityTradingContract: "Contrat de négoce d'électricité",
        projectInsurance: 'Assurance de projet',
        submit: 'Déposer',
        pdf: 'PDF'
      },
      invoices: {
        creditOpenTooltip: {
          heading: 'Crédit ouvert',
          text: 'Montant dû à Investerra SA'
        },
        confirmationModal: {
          title: 'Confirmation de la transaction',
          text: 'Veuillez confirmer le type de document et indiquer le montant exact.'
        },
        approveBill: 'Enregistrer la modification'
      }
    },
    dashboard: {
      cards: {
        projectOverview: {
          heading: 'Aperçu du projet',
          text: "Tous vos projets en un coup d'œil.",
          buttonText: 'Ouvrir'
        },
        platformCondition: {
          heading: 'Conditions de la plate-forme',
          text: 'Adapter les conditions pour les crédits, les produits, les partenaires, etc.',
          buttonText: 'Ouvrir'
        },
        tabs: {
          creditTerms: { heading: 'Crédit' },
          products: { heading: 'Produits' },
          partnerCommissions: { heading: "Conditions partenaires d'installation" }
        },
        invoice: {
          heading: 'Revue de la facture',
          text: "Vérification des factures d'installation et des confirmations de paiement.",
          buttonText: 'Ouvrir'
        },
        kycReview: {
          heading: 'Revue KYC',
          text: "Lien vers un fournisseur externe de KYC pour les contrôles d'identité.",
          buttonText: 'Ouvrir'
        },
        personalData: {
          heading: 'Données personnelles',
          text: 'Consulter et modifier les données personnelles enregistrées.',
          buttonText: 'Ouvrir'
        },
        clients: {
          heading: 'Aperçu des clients',
          text: "Aperçu de tous les clients d'Investerra",
          buttonText: 'Ouvrir'
        },
        partners: {
          heading: 'Aperçu des partenaires',
          text: "Aperçu de tous les partenaires d'Investerra",
          buttonText: 'Ouvrir'
        }
      }
    },
    clients: {
      clientOverview: 'Aperçu des clients',
      searchPlaceholder: 'Recherche par nom, e-mail ou ID',
      table: {
        heading: {
          firstName: 'Nom du client',
          email: 'Email',
          id: 'ID',
          lastChange: 'Dernière modification',
          bankDetails: 'Coordonnées bancaires',
          status: 'Statut'
        }
      },
      clientDetails: 'Détails du client',
      clientData: 'Infos clients',
      changesSaved: 'Les modifications ont été enregistrées avec succès'
    },
    partnerProducts: {
      table: {
        heading: {
          productName: 'Nom du produit'
        }
      }
    }
  },
  partnersOverview: {
    heading: 'Aperçu des partenaires',
    searchText: 'Recherche par nom de société, nom',
    partnerType: 'Type',
    table: {
      heading: {
        name: "Nom de l'entreprise",
        contactFirstName: 'Prénom et nom',
        partnerType: 'Type',
        partnerProducts: 'Produits',
        level: 'Niveau'
      }
    }
  },
  partners: {
    id: 'ID du partenaire',
    dashboard: {
      title: 'Tableau de bord du partenaire',
      cards: {
        myAccount: {
          heading: "Profil de l'entreprise",
          text: 'Consulter et modifier les données personnelles.',
          buttonText: 'Ouvrir'
        },
        financesAndContracts: {
          heading: 'Finances & contrats',
          text: "Tous les contrats et les questions financières en un coup d'œil.",
          buttonText: 'Ouvrir'
        },
        projectsOverview: {
          heading: 'Aperçu du projet',
          text: "Tous les projets en un coup d'œil.",
          buttonText: 'Ouvrir'
        },
        myBranches: {
          heading: 'Mes succursales',
          text: 'Consulter et modifier les succursales saisies.',
          buttonText: 'Ouvrir'
        },
        support: {
          heading: 'Aide & contact',
          text: 'Réponses aux questions les plus fréquentes et assistance directe.',
          buttonText: 'Ouvrir'
        }
      }
    },
    account: {
      companyData: {
        title: 'Données de la société',
        userData: 'Données utilisateur'
      },
      bankDetails: {
        title: 'Coordonnées bancaires'
      },
      security: {
        title: 'Sécurité'
      },
      contracts: {
        title: 'Contrats'
      },
      companyDataUpdated: 'Les données de votre entreprise ont été mises à jour.'
    },
    vatNumber: 'Numéro de TVA',
    legalForm: 'Forme juridique',
    averageClientRating: 'Note moyenne des clients',
    geoCoverage: 'Couverture géographique',
    geoCoverageTooltip:
      "Sélectionne les régions que tu traites. Seules les demandes d'offres provenant de ces régions te seront envoyées.",
    whichCantons: 'Clique sur tous les cantons dans lesquels ton entreprise est active:',
    allSwiss: 'Toute la Suisse',
    partnerProducts: {
      title: 'Offre de produits',
      description: 'Sélectionne toutes les catégories de produits que ton entreprise propose:',
      SOLAR_SYSTEM: 'Installation solaire',
      HEAT_PUMP: 'Pompe à chaleur',
      CHARGING_STATION: 'Station de recharge',
      RENOVATION: 'Rénovation'
    },
    registration: {
      title: "S'inscrire comme partenaire spécialisé",
      personalData: 'Données personnelles',
      instalationPartnerContract: 'Contrat de partenaire installateur',
      acceptContract: "J'accepte le contrat de partenaire installateur",
      downloadPdfContract: 'Téléchargez ici le PDF avec les conditions contractuelles',
      completeRegistration: "Terminer l'inscription",
      cantFindCompany:
        'Tu ne trouves pas le nom de ta société ? Contacte-nous ici <emailLink>hallo@investerra.ch</emailLink>'
    },
    acceptanceReport: {
      heading: "Télécharger le rapport d'acceptation",
      tooltip: "Info-bulle de rapport d'acceptation"
    },
    clientContactDetails: 'Détails du contact client',
    selectCompanyTitle: 'Veuillez choisir votre partenaire'
  },
  kycResult: {
    success: {
      heading: 'Nous avons réussi ! Merci beaucoup.',
      emailNotification:
        "Nous te prions de bien vouloir patienter. Tu seras informé(e) par e-mail lorsque l'examen sera terminé avec succès.",
      steps: 'Les prochaines étapes :',
      creditWorthiness: 'Nous vérifions ta solvabilité',
      applicationChecks: 'Ta demande sera examinée en interne. Cela prendra environ 2 jours.',
      confirmation: 'Tu reçois une confirmation / un refus de ta demande',
      projectFinancing: 'Ensuite, tu peux soumettre le financement de ton projet'
    },
    error: {
      heading: 'Mauvaise nouvelle.',
      enabledIdentification:
        "Malheureusement, nous n'avons pas pu mener à bien ton identification. Il peut y avoir plusieurs raisons à cela :",
      reasons: "Raisons d'une identification infructueuse :",
      incompleteInformation: 'Tes données étaient incomplètes',
      noMatchInfo: 'Tes documents ne correspondent pas aux indications',
      startProcessAgain:
        'Veuillez relancer le processus et vérifier que vos données sont complètes.'
    }
  },
  billing: {
    table: {
      heading: {
        date: 'Date',
        documentType: 'Type de document',
        partner: 'Partenaire installateur',
        status: 'Statut',
        partnersInvoice: 'Facture partenaire non confirmée'
      }
    },
    invoicesAndPayment: 'Factures & attestations de paiement',
    invoicesAndPaymentTooltip: 'Aperçu de toutes les transactions.',
    confirmDocumentType: 'Veuillez confirmer le type de document et indiquer le montant exact',
    pdf: 'PDF',
    creditPayoff: 'Remboursement du crédit',
    taxDocument: 'Document fiscal',
    creditTotalTooltip: {
      heading: 'Crédit total',
      text: 'Montant du crédit garanti ou décaissé.'
    },
    creditOpenTooltip: {
      heading: 'Crédit Ouvert',
      text: 'Montant dû à Investerra SA'
    },
    uploadQrInvoice: 'Télécharger la facture QR',
    invoicePdf: 'Facture PDF',
    uploadInterestCalculation: 'Télécharger le calcul des intérêts',
    uploadOneTimeInterest: 'Télécharger le calcul des intérêts uniques',
    interestCalculation: 'Frais et calcul des intérêts',
    interestCalculationText:
      'Investerra facture une seule fois des frais de base, ainsi que les intérêts courus pour les éventuels paiements partiels. Tu trouveras la facture à télécharger ci-dessous.'
  },
  partnerDetails: {
    heading: 'Détails sur le partenaire',
    tabs: { partnerInfo: 'Infos partenaires', partnerCommissions: 'Conditions de crédit' },
    partnerInfo: { companyData: 'Données de la société', productRange: 'Offre de produits' }
  },
  checks: {
    monthlyRate: 'Mensualité',
    allowance: 'Montant de la franchise',
    installationPartner: {
      label: 'Partenaire installateur'
    },
    generalCheck: {
      label: 'Examen général',
      value: 'Volume, durabilité, type de projet / installation, localisation'
    },
    creditCheck: {
      label: 'Contrôle de solvabilité',
      value: "Solvabilité pour éviter le surendettement de l'emprunteur"
    },
    creditWorthiness: {
      label: "Capacité d'emprunt",
      value: 'Octroi de crédit selon la LCC'
    }
  },
  creditTerms: {
    base_fee: {
      heading: 'Frais de base minimum',
      description: 'Frais de dossier uniques à la souscription'
    },
    percentage_completion_fee: {
      heading: 'Pourcentage des frais de base',
      description: 'Minimum de frais de souscription uniques sur le volume'
    },
    refinancing_rate: {
      heading: 'Taux de refinancement',
      description: "Taux d'intérêt auquel nous obtenons le refinancement"
    },
    margin_platform: {
      heading: 'Marge de la plateforme',
      description: 'Marge de la plateforme'
    },
    interest_rate: {
      heading: "Taux d'intérêt contrat de crédit",
      description:
        "Taux d'intérêt effectif utilisé pour calculer les mensualités. (Addition du taux de refinancement et de la marge)"
    },
    min_loan_amount: {
      heading: 'Montant minimum du crédit',
      description: ''
    },
    max_loan_amount: {
      heading: 'Montant maximal du crédit',
      description: ''
    },
    min_loan_duration_in_years: {
      heading: 'Durée minimale du crédit en années',
      description: ''
    },
    max_loan_duration_in_years: {
      heading: 'Durée maximale du crédit en années',
      description: ''
    },
    min_down_payment: {
      heading: "Paiement minimum d'acompte",
      description: ''
    },
    max_down_payment: {
      heading: "Paiement maximal d'acompte",
      description: ''
    },
    max_down_payment_percentage: {
      heading: "Pourcentage maximum d'acompte",
      description: ''
    },
    kleiv_base_amount: {
      heading: 'Montant de base PRU:',
      description: ''
    },
    kleiv_altitude_bounds: {
      heading: 'Limite supérieure PRU',
      description: ''
    },
    indicative_price_per_kwp_small: {
      heading: 'Prix indicatif par kWc (petites installations)',
      description: ''
    },
    indicative_price_per_kwp_medium: {
      heading: 'Prix indicatif par kWc (installations moyennes)',
      description: ''
    },
    indicative_price_per_kwp_big: {
      heading: 'Prix indicatif par kWc (grandes installations)',
      description: ''
    },
    vat: {
      heading: 'TVA',
      description: ''
    },
    feed_in_tariff: {
      heading: 'Rétribution du courant injecté',
      description: ''
    },
    altitude_bonus_threshold: {
      heading: "Limite d'altitude subventionnement",
      description:
        "Les installations à partir de cette altitude bénéficient d'une subvention supplémentaire"
    }
  },
  partnerCommissions: {
    table: {
      heading: {
        LEVEL: 'Niveau',
        BROKERED_VALUE: 'Volume min. de placement / an',
        ORDER_VOLUME: 'Commission volume des commandes',
        LOAN_AMOUNT: 'Commission volume de crédit',
        PAYMENT_TERMS: 'Conditions de paiement'
      }
    }
  },
  partnerProjectSummary: {
    tabs: { projectDetails: 'Détails du projet', partnerBilling: 'Factures' }
  },
  partnerProjectDetails: {
    customerContactDetails: {
      tooltip: { text: 'Coordonnées du client' },
      headline: 'Détails du contact client'
    },
    offerStatus: "Statut de l'offre"
  },
  partnerBilling: {
    heading: 'Factures des projets',
    table: {
      heading: { date: 'Date', documentType: 'Type de document', status: 'Statut', download: '' }
    }
  },
  allInvoices: {
    invoiceReview: 'Revue des factures',
    searchPlaceholder: 'Recherche par adresse ou numéro de projet',
    table: {
      heading: {
        project: {
          id: 'Projet #',
          building: { address: { street: 'Adresse du projet' } },
          offer: { partner: { name: "Partenaire d'installation" } }
        },
        timestamp: 'Date',
        type: 'Type de document',
        status: 'Statut'
      }
    }
  },
  kycIdentification: {
    heading: 'Vérification de ton identité',
    text: 'Ton identité est vérifiée en ligne, en toute sécurité, en quelques étapes simples. Tu peux compléter le processus de deux manières différentes, soit avec ton smartphone et le code QR, soit via un lien ici dans ton navigateur. Le processus ne prendra que quelques minutes à chaque fois.',
    thirdPartyText:
      "Indice: Pour la vérification d'identité, Investerra collabore avec l'entreprise Intrum. Tu seras redirigé vers la plateforme d'Intrum pour cette vérification. Une fois la vérification terminée, tu reviendras automatiquement sur le site d'Investerra, sauf si tu passes sur ton mobile.",
    scanAndStart: 'Scanner et lancer le processus',
    verificationWithQrCode: 'Vérification avec code QR',
    verifyWithLink: 'Vérification par lien directement dans le navigateur',
    prepareSmartphone: 'Prépare ton smartphone et démarre le processus en scannant le code QR.',
    buttonDescription: 'Démarre le processus en cliquant sur le bouton.',
    buttonText: "Démarre l'identification",
    yourIdentId: 'Ton Ident-ID'
  },
  deleteProject: {
    heading: 'Supprimer un projet',
    text: 'Si vous confirmez, le projet sera supprimé et les données seront perdues.',
    confirmationMessage: 'Projet supprimé avec succès',
    deleteNotAllowed: "La suppression de projets n'est pas autorisée"
  },
  sepLegend: {
    low: 'Faible : < 800 kWh/m2',
    medium: 'Moyenne : > 800 et < 1000 kWh/m2',
    good: 'Bon:> 1000 et < 1200 kWh/m2',
    veryGood: 'Très bon:> 1200 et < 1400 kWh/m2',
    excelent: 'Excellent : > 1400 kWh/m2'
  },
  commissionUnits: { DAYS: ' Jours' },
  creditCheck: {
    personalDetails: {
      title: 'Données personnelles du demandeur',
      name: 'Nom',
      data: 'Données personnelles',
      gender: 'Sexe',
      maritalStatus: 'État civil',
      contactDetails: 'Coordonnées',
      married: 'Marié(e)',
      widowed: 'Veuf(ve)',
      divorced: 'Divorcé(e)',
      single: 'Célibataire',
      separated: 'Séparé',
      registeredPartnership: 'Partenariat enregistré',
      selectGender: 'Sélectionnez votre sexe',
      nationality: 'Nationalité',
      chooseNationality: 'Choisissez votre nationalité',
      residencePermit: 'Permis de séjour',
      residencePermitType: 'Type de permis de séjour',
      doYouHaveResidencePermit:
        'Possèdes-tu un permis de séjour suisse ? Si le type de ton permis de séjour ne figure pas dans la liste ci-dessous, contacte-nous directement.',
      changePhoneNumber:
        'Vous pouvez changer votre numéro de téléphone dans les <changeLink>Données personnelles</changeLink>.',
      changePhoneNumberWarning:
        'Pour compléter les Données personnelles, veuillez fournir votre numéro de téléphone <text>ici</text>.'
    },
    lifePartner: {
      title: 'Conjoint(e)',
      lifePartnerIn: 'Conjoint(e)',
      maritalStatusPartner: 'État civil du/de la conjoint(e)',
      placeOfResidence: 'Lieu de résidence du/de la conjoint(e)',
      jointPlaceOfResidence: 'Résidence commune',
      meansOfTransport: 'Moyen de transport du/de la conjoint(e)',
      meansOfTransportWork: 'Moyen(s) de transport pour se rendre au travail',
      partnerMonthlyIncome: 'Quel est le revenu mensuel du partenaire?',
      monthlyTransportationCosts:
        "À combien s'élèvent les frais de transport mensuels du partenaire de vie?"
    },
    housingSituation: {
      title: 'Situation du logement',
      householdPlaceholder: 'Type de ménage',
      selectHouseholdType: 'Veuillez sélectionner votre type de ménage:',
      householdTypeText:
        'Remarque : Si des enfants ainsi que des personnes ayant besoin de soutien vivent dans le ménage, il convient de choisir le type de ménage en tenant compte des enfants.',
      selectAddressType: "Choisis ton type d'adresse:",
      residingSince: 'Résidant depuis',
      residenceTypePlaceholder: "Lien avec l'adresse de ton domicile",
      selectHousingReference: "Choisis la référence à l'adresse de ton domicile",
      numberOfHouseholdMembers: 'Nombre de personnes dans le ménage',
      howManyAdultsInHousehold:
        "Combien d'adultes vivent dans le même ménage que toi et bénéficient d'un soutien financier de ta part?",
      howManyChildrenInHousehold: "Combien d'enfants mineurs vivent dans le même ménage ?",
      householdMembers: 'Membres du ménage',
      numberOfChildren: "Nombre d'enfants",
      householdInsuranceFee: "Prime d'assurance maladie",
      householdInsuranceFeeText:
        "Le montant total des frais d'assurance maladie pour le demandeur/la demandeuse et, le cas échéant, pour son/sa partenaire et ses enfants dans le ménage.",
      childAge: "Âge de l'enfant",
      customerAddress: {
        heading: 'Lieu de résidence',
        street: 'Rue',
        houseNumber: 'N° de maison',
        postalCode: 'Code Postal',
        municipality: 'Lieu',
        country: 'Pays',
        canton: 'Canton'
      }
    },
    financialSituation: {
      title: 'Situation financière du demandeur/de la demanderesse',
      bankDetails: 'Quelles sont tes coordonnées bancaires?',
      accountHolderName: 'Quels sont le prénom et le nom du titulaire du compte?',
      employmentStatus: "Rapport d'emploi",
      employmentStatusText: 'Quelle est ta situation professionnelle',
      monthlyIncomeGrossAmountMain: 'Quel est ton revenu mensuel (brut) en CHF?',
      thirteenSalary: 'Je reçois un 13e mois de salaire',
      additionalIncome: 'As-tu un revenu supplémentaire?',
      ammountOfAdditionalIncome:
        'Si tu as un revenu supplémentaire, qui est par exemple versé sous forme de bonus : Quel est le montant de ce revenu supplémentaire par mois?',
      additionalIncomeThirteenSalary:
        'Je perçois un 13e mois de salaire sur mon revenu complémentaire',
      rentalCostMonthly: 'Quel est le montant de ton loyer par mois?',
      rentalCostMonthlyExamples: '(p. ex. loyer, intérêt hypothécaire)',
      workTransport: 'Moyen de transport',
      workTransportText: 'Quel moyen de transport utilises-tu pour te rendre au travail?',
      monthlyTransportationCosts:
        "A combien s'élèvent tes dépenses mensuelles pour ces moyens de transport?",
      onlyIfPublicTransportUsed:
        'Seulement si tu utilises les transports publics ou/et ta voiture.',
      workingDaysPerMonth: 'Combien de jours de travailles-tu par mois?',
      workingDaysPerMonthText: 'Le temps de travail moyen par mois est de 22 jours à plein temps.',
      changeBankDetails:
        'Tu peux changer le numéro de ton compte bancaire sous <changeLink>Coordonnées bancaires</changeLink>.',
      changeBankDetailsWarning:
        'Pour compléter les informations de ton compte bancaire, tu dois entrer le nom de ta banque et ton IBAN <text>ici</text>.',
      isChurchTaxObligatory: 'Payez-vous un impôt religieux ?',
      monthlyEducationCosts:
        "À combien s'élèvent tes frais mensuels de formation/formation continue ?",
      monthlyOtherFixExpenses: "À combien s'élèvent tes autres charges mensuelles?",
      monthlyOneTimeExpenses:
        'As-tu prévu des dépenses ponctuelles importantes pendant les trois prochaines années?',
      majorExpensesPlanned:
        "Quelles sont les dépenses ponctuelles importantes que tu as prévues pendant les trois prochaines années (par ex. achat d'une voiture/d'un appartement, dépenses médicales, etc.)",
      monthlyExpensesAlimony:
        "Si tu paies une pension alimentaire, à combien s'élèvent les montants mensuels ",
      monthlyExpensesAlimonyText: 'Seulement si tu paies une pension alimentaire',
      confirmInformationIsCorrect:
        "Par la présente, je confirme l'exactitude des informations fournies",
      confirmationText:
        "En tant que partenaire contractuel, je déclare que je suis seul titulaire du droit économique sur ces biens et que  mes données personnelles ont été correctement enregistrées. Si d'autres personnes ont un droit bénéficiaire  sur les actifs apportés dans la relation commerciale, je m'engage à en informer Investerra AG. En tant que  partenaire contractuel, je m'engage à communiquer toute modification sans qu'on me le demande.",
      beneficialOwnerText:
        'Constitue une infraction pénale la fourniture délibérée de fausses informations sur ce formulaire (art. 251 du Code pénal suisse, falsification de documents)',
      correctnessOfInformation: 'Exactitude des données & justification économique'
    },
    lifePartnerFinancialSituation: {
      title: 'Situation financière du/de la partenaire',
      lifePartnerEmploymentStatus: "Quel est le statut d'emploi de ton/ta partenaire?",
      lifePartnerMonthlyIncomeGrossAmountMain:
        'Quel est le revenu mensuel de ton/ta partenaire (brut) en CHF ?',
      additionalIncome: 'Ton/ta partenaire a-t-il/elle un revenu supplémentaire?',
      ammountOfAdditionalIncome:
        'Si ton partenaire a un revenu supplémentaire, qui est par exemple versé sous forme de bonus : Quel est le montant de ce revenu supplémentaire par mois?',
      additionalIncomeThirteenSalary: 'Mein Lebenspartner erhält einen 13. zusätzliches Monatslohn',
      monthlyHealthInsurance: "Quel est le montant de ta prime d'assurance maladie mensuelle?",
      monthlyAlimony:
        'Quel est le montant mensuel de la pension alimentaire versée par ton/ta partenaire ?',
      onlyIfYouPayAlimony: 'Seulement si tu paies une pension alimentaire.',
      monthlyEducationCosts:
        "A combien s'élèvent les frais mensuels de formation/formation continue de ton/ta partenaire?",
      monthlyOtherFixExpenses:
        'Quelles sont les autres charges mensuelles supplémentaires de ton/ta partenaire?',
      monthlyOneTimeExpenses:
        'A ton/ta partenaire prévu des dépenses ponctuelles importantes pendant les trois prochaines années?',
      majorExpensesPlanned:
        "Quelles sont les dépenses ponctuelles importantes que ton/ta partenaire a prévues pendant les trois prochaines années (par ex. achat d'une voiture/d'un appartement, dépenses médicales, etc.)",
      yearlyIncome: 'Quel est ton revenu annuel (brut) en CHF?',
      thirteenSalary: 'Mon partenaire reçoit un 13e mois de salaire',
      rentalCostMonthly: "A combien s'élèvent les frais de logement de ton/ta partenaire par mois?",
      workTransportText:
        'Quels sont les moyens de transport utilisés par ton/ta partenaire pour se rendre au travail?',
      monthlyTransportationCosts:
        "À combien s'élèvent les dépenses mensuelles pour ces moyens de transport?",
      onlyIfPublicTransportUsed:
        'Seulement si tu utilises les transports publics ou/et ta voiture.',
      workingDaysPerMonth:
        'Combien de jours de travail ton/ta partenaire travaille-t-il/elle par mois?',
      isChurchTaxObligatory: "Ton/ta partenaire paie-t-il/elle l'impôt ecclésiastique?"
    },
    maritalStatus: {
      MARRIED: 'Marié(e)',
      WIDOWED: 'Veuf(ve)',
      DIVORCED: 'Divorcé(e)',
      SINGLE: 'Célibataire',
      SEPARATED: 'Séparé',
      REGISTERED: 'Partenariat enregistré',
      JURIDICAL_RESOLVED: 'Clarification juridique'
    },
    householdType: {
      SINGLE: 'Célibataire',
      MARRIED_COUPLE: 'Couple marié / partenariat enregistré / couple avec enfants communs',
      SINGLE_HOUSEHOLD_COMMUNITY: 'Célibataire dans une communauté de ménage avec des adultes',
      SINGLE_PARENT: 'Parent célibataire',
      SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD:
        'Célibataire vivant avec une personne nécessitant de l’aide dans le même ménage',
      SINGLE_PARENT_HOUSEHOLD_COMMUNITY:
        'Parent célibataire dans une communauté de ménage avec des adultes'
    },
    residenceType: {
      FAMILY: 'Famille',
      EMPLOYER: 'Employeur désigné',
      NO_PERMANENT_RESIDENCE: 'Pas de domicile fixe',
      OWNER_WITH_MORTGAGE: 'Propriétaire avec hypothèque',
      OWNER_WITHOUT_MORTGAGE: 'Propriétaire sans hypothèque',
      RENTER: 'Locataire'
    },
    addressType: {
      CURRENT: 'Adresse de résidence principale',
      MAILING_CORRESPONDENCE: 'Adresse de correspondance',
      PREVIOUS: 'Ancienne adresse de résidence',
      EMPLOYMENT: 'Adresse professionnelle',
      TEMPORARY: 'Adresse de résidence temporaire'
    },
    transportType: {
      NONE: 'Aucun',
      BICYCLE: 'Vélo',
      MOPED: 'Cyclomoteur',
      MOTORCYCLE: 'Moto',
      CAR: 'Voiture',
      PUBLIC: 'Transports publics'
    },
    employmentStatus: {
      EMPLOYED: 'Employé',
      UNEMPLOYED: 'Chômeur',
      SELF_EMPLOYED: 'Travailleur Indépendant',
      STUDENT: 'Étudiant',
      PENSION: 'Pension',
      TEMPORARY_EMPLOYMENT: 'Emploi Temporaire',
      HOUSE_WIFE_MAN: 'Femme/Homme Au Foyer'
    }
  },
  projectLogs: {
    CREATED: 'Projet créé',
    QUESTIONAIRE_SUBMITTED: 'Questionnaire soumis',
    FOUR_EYE_CHECK_REQUESTED: 'Contrôle à quatre yeux demandé',
    FOUR_EYE_CHECK_APPROVED: 'Contrôle à quatre yeux approuvé',
    FOUR_EYE_CHECK_REJECTED: 'Contrôle à quatre yeux rejeté',
    FOUR_EYE_CHECK_REOPENED: 'Contrôle à quatre yeux rouvert',
    PROJECT_APPROVED: 'Projet approuvé',
    PROJECT_DECLINED: 'Projet refusé',
    PROJECT_RESET: 'Projet réinitialisé',
    PROJECT_RETRIGGER: 'Projet relancé'
  },
  leaveModal: {
    title: 'Quitter la page?',
    description: 'Les modifications que tu as effectuées ne seront peut-être pas enregistrées.'
  },

  // ENUMS
  errorCode: { FILE_ALREADY_EXISTS: 'Le fichier existe déjà' },
  partnerType: { SALES_PARTNER: 'Distributeur', INSTALLATION_PARTNER: 'Partenaire installateur' },
  partnerProducts: {
    HEAT_PUMP: 'Pompe à chaleur',
    E_CHARGING_STATION: 'Station de recharge',
    STORAGE_SOLUTION: 'Solution de stockage',
    SOLAR_SYSTEM: 'Installation solaire',
    INVESTERRA_ELECTRICITY_SALES: "Vente d'électricité par Investerra"
  },
  clientStatuses: {
    KYC_OPEN: 'KYC / LCC ouvert',
    KKG_FINAL: 'LCC final',
    AML_FINAL: 'LBA final',
    FIRST_CHECK_SUCCESS: 'Premierère vérification réussi',
    FIRST_CHECK_FAIL: 'Échec de la première vérification',
    SECOND_CHECK_SUCCESS: 'Deuxième vérification réussi',
    SECOND_CHECK_FAIL: 'Échec de la deuxième vérification',
    KYC_SUCCESS: 'KYC réussi',
    KYC_FAIL: 'Échec de la vérification KYC',
    ADMONISHED: 'Administré'
  },
  partnerLevel: {
    STARTER: 'Starter / Go Green',
    VOLT_ON: 'Volt On / Économiseur de CO2',
    HEAVY_CURRENT: 'Courant fort / Activiste du développement durable',
    POWER_PLANT: 'Centrale électrique / Greeny'
  },
  transactionType: {
    INVOICE: 'Facture',
    PAYMENT_CONFIRMATION: 'Confirmation de paiement'
  },
  transactionStatus: {
    OPEN: 'Ouvert',
    USER_APPROVAL_PENDING: 'Approbation du client en attente',
    UNDER_ASSESSMENT: 'En cours de vérification',
    APPROVED: 'Approuvé',
    PAID: 'Payé',
    REJECTED: 'Refusé'
  },
  projectStatus: {
    OFFER_REQUESTED: "Demande d'offre d'installation",
    OFFER_RECEIVED: "Recevoir une offre d'installation",
    CHECK_OPEN: 'Vérification débutée',
    CHECK_APPROVE_REQUESTED: '2e vérification débutée',
    CHECK_FAILED: 'Échec de la vérification',
    CHECK_DONE: 'Vérification terminée',
    CONTRACTS_ISSUED: 'Contrats établis',
    CLIENT_SIGNED: 'Signé par le client',
    LOAN_OPEN: 'Crédit ouvert',
    LOAN_CLOSED: 'Crédit contracté',
    // KOTlIN STATUSES
    quoteApproved: 'Financement pour offre demandé',
    questionaireSubmitted: "Questionnaire d'examen rempli - Examen ouvert",
    kycPassed: "Questionnaire d'examen rempli - Examen ouvert",
    kycFailed: "Questionnaire d'examen rempli - Examen ouvert",
    creditCheckCompleted: "Questionnaire d'examen rempli - Examen ouvert",
    fourEyeCheckToBeReviewedBySuperAdmin: "Questionnaire d'examen rempli - Examen ouvert",
    reopened: "Questionnaire d'examen rempli - Examen ouvert",
    fourEyeCheckPassed: 'Examen accepté',
    fourEyeCheckFailed: 'Examen refusé',
    closed: 'Fermé'
  },
  offerStatus: {
    PENDING: 'Ouvert',
    CREATED: 'Créé',
    ACCEPTED: 'Adopté',
    DECLINED: 'Refusé',
    EXPIRED: 'Expiré',
    OFFER_IN_DELIVERY: 'En coours de livraison',
    OFFER_COMPLETED: 'Terminé'
  },
  financingOfferStatus: {
    indicative: 'Indicatif',
    final: 'Final'
  },
  role: {
    ADMIN: 'Admin',
    USER: 'Utilisateur'
  },
  cantons: {
    AG: 'Argovie',
    AI: 'Appenzell Rh-I',
    AR: 'Appenzell Rh-E',
    BE: 'Berne',
    BL: 'Bâle-Campagne',
    BS: 'Bâle-Ville',
    FR: 'Fribourg',
    GE: 'Genève',
    GL: 'Glaris',
    GR: 'Grisons',
    JU: 'Jura',
    LU: 'Lucerne',
    NE: 'Neuchâtel',
    NW: 'Nidwald',
    OW: 'Obwald',
    SG: 'Saint-Gall',
    SH: 'Schaffhouse',
    SO: 'Soleure',
    SZ: 'Schwyz',
    TG: 'Thurgovie',
    TI: 'Tessin',
    UR: 'Uri',
    VD: 'Vaud',
    VS: 'Valais',
    ZG: 'Zoug',
    ZH: 'Zurich'
  },
  country: {
    SWITZERLAND: 'Suisse',
    GERMANY: 'Allemagne',
    AUSTRIA: 'Autriche',
    ITALY: 'Italie',
    FRANCE: 'France',
    LIECHTENSTEIN: 'Liechtenstein',
    AFGHANISTAN: 'Afghanistan',
    ALBANIA: 'Albanie',
    ALGERIA: 'Algérie',
    ANDORRA: 'Andorre',
    ANGOLA: 'Angola',
    ANTIGUA_AND_DEPS: 'Antigua Et Dépendances',
    ARGENTINA: 'Argentine',
    ARMENIA: 'Arménie',
    AUSTRALIA: 'Australie',
    AZERBAIJAN: 'Azerbaïdjan',
    BAHAMAS: 'Bahamas',
    BAHRAIN: 'Bahreïn',
    BANGLADESH: 'Bangladesh',
    BARBADOS: 'Barbade',
    BELARUS: 'Biélorussie',
    BELGIUM: 'Belgique',
    BELIZE: 'Belize',
    BENIN: 'Bénin',
    BHUTAN: 'Bhoutan',
    BOLIVIA: 'Bolivie',
    BOSNIA_HERZEGOVINA: 'Bosnie Herzégovine',
    BOTSWANA: 'Botswana',
    BRAZIL: 'Brésil',
    BRUNEI: 'Brunéi',
    BULGARIA: 'Bulgarie',
    BURKINA: 'Burkina',
    BURUNDI: 'Burundi',
    CAMBODIA: 'Cambodge',
    CAMEROON: 'Cameroun',
    CANADA: 'Canada',
    CAPE_VERDE: 'Cap Vert',
    CENTRAL_AFRICAN_REP: 'République Centrafricaine',
    CHAD: 'Tchad',
    CHILE: 'Chili',
    CHINA: 'Chine',
    COLOMBIA: 'Colombie',
    COMOROS: 'Comores',
    CONGO: 'Congo',
    CONGO_DEMOCRATIC_REP: 'République Démocratique Du Congo',
    COSTA_RICA: 'Costa Rica',
    CROATIA: 'Croatie',
    CUBA: 'Cuba',
    CYPRUS: 'Chypre',
    CZECH_REPUBLIC: 'République Tchèque',
    DENMARK: 'Danemark',
    DJIBOUTI: 'Djibouti',
    DOMINICA: 'Dominique',
    DOMINICAN_REPUBLIC: 'République Dominicaine',
    EAST_TIMOR: 'Timor Oriental',
    ECUADOR: 'Équateur',
    EGYPT: 'Égypte',
    EL_SALVADOR: 'El Salvador',
    EQUATORIAL_GUINEA: 'Guinée Équatoriale',
    ERITREA: 'Érythrée',
    ESTONIA: 'Estonie',
    ETHIOPIA: 'Éthiopie',
    FIJI: 'Fidji',
    FINLAND: 'Finlande',
    GABON: 'Gabon',
    GAMBIA: 'Gambie',
    GEORGIA: 'Géorgie',
    GHANA: 'Ghana',
    GREECE: 'Grèce',
    GRENADA: 'Grenade',
    GUATEMALA: 'Guatemala',
    GUINEA: 'Guinée',
    GUINEA_BISSAU: 'Guinée Bissau',
    GUYANA: 'Guyana',
    HAITI: 'Haïti',
    HONDURAS: 'Honduras',
    HUNGARY: 'Hongrie',
    ICELAND: 'Islande',
    INDIA: 'Inde',
    INDONESIA: 'Indonésie',
    IRAN: 'Iran',
    IRAQ: 'Irak',
    IRELAND_REPUBLIC: "République D'Irlande",
    ISRAEL: 'Israël',
    IVORY_COAST: "Côte D'Ivoire",
    JAMAICA: 'Jamaïque',
    JAPAN: 'Japon',
    JORDAN: 'Jordanie',
    KAZAKHSTAN: 'Kazakhstan',
    KENYA: 'Kenya',
    KIRIBATI: 'Kiribati',
    KOREA_NORTH: 'Corée Du Nord',
    KOREA_SOUTH: 'Corée Du Sud',
    KOSOVO: 'Kosovo',
    KUWAIT: 'Koweït',
    KYRGYZSTAN: 'Kirghizistan',
    LAOS: 'Laos',
    LATVIA: 'Lettonie',
    LEBANON: 'Liban',
    LESOTHO: 'Lesotho',
    LIBERIA: 'Libéria',
    LIBYA: 'Libye',
    LITHUANIA: 'Lituanie',
    LUXEMBOURG: 'Luxembourg',
    MACEDONIA: 'Macédoine',
    MADAGASCAR: 'Madagascar',
    MALAWI: 'Malawi',
    MALAYSIA: 'Malaisie',
    MALDIVES: 'Maldives',
    MALI: 'Mali',
    MALTA: 'Malte',
    MARSHALL_ISLANDS: 'Îles Marshall',
    MAURITANIA: 'Mauritanie',
    MAURITIUS: 'Maurice',
    MEXICO: 'Mexique',
    MICRONESIA: 'Micronésie',
    MOLDOVA: 'Moldavie',
    MONACO: 'Monaco',
    MONGOLIA: 'Mongolie',
    MONTENEGRO: 'Monténégro',
    MOROCCO: 'Maroc',
    MOZAMBIQUE: 'Mozambique',
    MYANMAR_BURMA: 'Myanmar Birmanie',
    NAMIBIA: 'Namibie',
    NAURU: 'Nauru',
    NEPAL: 'Népal',
    NETHERLANDS: 'Pays-Bas',
    NEW_ZEALAND: 'Nouvelle-Zélande',
    NICARAGUA: 'Nicaragua',
    NIGER: 'Niger',
    NIGERIA: 'Nigéria',
    NORWAY: 'Norvège',
    OMAN: 'Oman',
    PAKISTAN: 'Pakistan',
    PALAU: 'Palaos',
    PANAMA: 'Panama',
    PAPUA_NEW_GUINEA: 'Papouasie Nouvelle Guinée',
    PARAGUAY: 'Paraguay',
    PERU: 'Pérou',
    PHILIPPINES: 'Philippines',
    POLAND: 'Pologne',
    PORTUGAL: 'Portugal',
    QATAR: 'Qatar',
    ROMANIA: 'Roumanie',
    RUSSIAN_FEDERATION: 'Fédération De Russie',
    RWANDA: 'Rwanda',
    ST_KITTS_AND_NEVIS: 'Saint-Kitts-Et-Nevis',
    ST_LUCIA: 'Sainte-Lucie',
    SAINT_VINCENT_AND_THE_GRENADINES: 'Saint-Vincent-Et-Les-Grenadines',
    SAMOA: 'Samoa',
    SAN_MARINO: 'Saint-Marin',
    SAO_TOME_AND_PRINCIPE: 'Sao Tomé Et Principe',
    SAUDI_ARABIA: 'Arabie Saoudite',
    SENEGAL: 'Sénégal',
    SERBIA: 'Serbie',
    SEYCHELLES: 'Seychelles',
    SIERRA_LEONE: 'Sierra Leone',
    SINGAPORE: 'Singapour',
    SLOVAKIA: 'Slovaquie',
    SLOVENIA: 'Slovénie',
    SOLOMON_ISLANDS: 'Îles Salomon',
    SOMALIA: 'Somalie',
    SOUTH_AFRICA: 'Afrique Du Sud',
    SOUTH_SUDAN: 'Soudan Du Sud',
    SPAIN: 'Espagne',
    SRI_LANKA: 'Sri Lanka',
    SUDAN: 'Soudan',
    SURINAME: 'Suriname',
    SWAZILAND: 'Eswatini',
    SWEDEN: 'Suède',
    SYRIA: 'Syrie',
    TAIWAN: 'Taïwan',
    TAJIKISTAN: 'Tadjikistan',
    TANZANIA: 'Tanzanie',
    THAILAND: 'Thaïlande',
    TOGO: 'Togo',
    TONGA: 'Tonga',
    TRINIDAD_AND_TOBAGO: 'Trinité-Et-Tobago',
    TUNISIA: 'Tunisie',
    TURKEY: 'Turquie',
    TURKMENISTAN: 'Turkménistan',
    TUVALU: 'Tuvalu',
    UGANDA: 'Ouganda',
    UKRAINE: 'Ukraine',
    UNITED_ARAB_EMIRATES: 'Émirats Arabes Unis',
    UNITED_KINGDOM: 'Royaume-Uni',
    UNITED_STATES: 'États-Unis',
    URUGUAY: 'Uruguay',
    UZBEKISTAN: 'Ouzbékistan',
    VANUATU: 'Vanuatu',
    VATICAN_CITY: 'Cité Du Vatican',
    VENEZUELA: 'Venezuela',
    VIETNAM: 'Vietnam',
    YEMEN: 'Yémen',
    ZAMBIA: 'Zambie',
    ZIMBABWE: 'Zimbabwe'
  },
  gender: {
    MALE: 'Mâle',
    FEMALE: 'Femme',
    OTHER: 'Autres'
  },
  months: {
    Jan: 'janvier',
    Feb: 'février',
    Mar: 'mars',
    Apr: 'avril',
    May: 'mai',
    Jun: 'juin',
    Jul: 'juillet',
    Aug: 'août',
    Sep: 'septembre',
    Oct: 'octobre',
    Nov: 'novembre',
    Dec: 'décembre'
  },
  languages: { fr: 'FR', de: 'DE' },
  // BASIC WORDS
  iban: 'IBAN',
  days: 'Jours',
  dateOfBirth: 'Date de naissance',
  placeOfBirth: 'Lieu de naissance',
  solarSystemHeading: 'Installation solaire',
  installationPartner: 'Partenaire installateur',
  noOptions: 'Aucune option',
  close: 'Fermer',
  loading: 'Chargement',
  download: 'télécharger',
  print: 'imprimer',
  saveAsProject: 'Enregistrer en tant que projet',
  confirm: 'Confirmer',
  search: 'Recherche',
  searchAddress: "Cherche ici l'adresse de ton bien.",
  searchFile: 'Sélectionner un fichier',
  next: 'Continuer',
  back: 'Retour',
  save: 'Enregistrer',
  nextStep: 'Prochaine étape',
  getQuote: 'Demander une offre',
  startFinancing: "Lancer l'évaluation du financement",
  fromKleiv: 'KLEIV',
  years: 'Années',
  depozit: 'Acompte',
  sending: 'Envoyer',
  to: "Jusqu'à",
  endDate: 'Date de fin',
  startDate: 'Date de début',
  resetAllFilters: 'Réinitialiser tous les filtres',
  date: 'Date',
  notFound: 'Non trouvé',
  all: 'Tous les',
  month: 'Mois',
  fileUploadedSuccesfull: 'Fichier téléchargé avec succès',
  transactionUpdated: 'Transaction mise à jour',
  filter: 'Filtre',
  pleaseLeaveComment: 'Veuillez laisser un commentaire',
  selectStatus: 'Sélectionner le statut',
  monthly: 'p. / mois',
  uploadPDF: 'Télécharger le PDF',
  credit: 'Crédit',
  total: 'Total',
  year: 'Année',
  open: 'Ouvert',
  level: 'Niveau',
  changesSaved: 'Modifications enregistrées',
  changeStatus: 'Modifier le statut',
  saveChanges: 'Enregistrer les modifications',
  cancel: 'Annuler',
  yes: 'Oui',
  no: 'Non',
  copy: 'Copier',
  call: 'Appel',
  address: 'Adresse',
  value: 'Valeur',
  kilowatt: 'kWh',
  uploaded: 'Téléchargé',
  verifyEmail:
    "Si vous n'avez pas reçu l'e-mail de confirmation ou s'il a expiré, cliquez sur le bouton ci-dessous pour le renvoyer.",
  sendVerificationEmailAgain: "Renvoyer l'e-mail de confirmation",
  companyHeadquarters: "Siège de l'entreprise",
  email: 'e-mail',
  nextAction: 'Prochaine action',
  from: 'à partir de',
  pageNotFound: 'Page non trouvée',
  backToHomepage: "Retour à la page d'accueil",
  companyName: "Nom de l'entreprise",
  name: 'Prénom',
  lastName: 'Nom',
  emailAddress: 'Adresse e-mail ',
  phoneNumber: 'Numéro de téléphone',
  password: 'Mot de passe',
  confirmPassword: 'Répéter le mot de passe',
  street: 'Rue',
  houseNumber: 'Numéro',
  streetAndHouseNumber: 'Rue et numéro',
  zipCode: 'Code Postal',
  location: 'Lieu',
  mobileNumber: 'Numéro de téléphone (mobile)',
  francsMonthly: 'CHF / mois',
  downloadAcceptanceReport: 'Télécharger le rapport de réception',
  offerDetails: "Détails de l'offre",
  customerNumber: 'Numéro de client',
  fullName: 'Prénom et nom',
  bankDetails: 'Coordonnées bancaires',
  switzerland: 'Suisse',
  leave: 'Quitter',
  personalDetails: 'Données personnelles',
  products: 'Produits',
  status: 'Statut',
  reopen: 'Rouvrir',
  toMyProfile: 'Vers mon profil',
  toHomepage: "Vers la page d'accueil",
  restartIdentification: "Redémarrer l'identification",
  uploadSuccessful: 'Téléchargement réussi',
  monthlyIncome: 'Revenus monétaires',
  monthlyTransportationCosts: 'Frais de transport mensuels',
  readyForUpload: 'Prêt à télécharger',
  edit: 'Modifier',
  contactInvesterra: 'Contact avec investerra'
};
