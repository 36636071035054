import { Dispatch, useEffect, useState } from 'react';
import './MESMap.scss';
import MESSearch from '../MESSearch/MESSearch';
import { ProjectActionTypes } from '../../../store/project/project.reducer';
import { Building } from '../../../types/sepMap.types';
import { formatFeedInTariff } from '../../../store/mes/mes.actions';

interface MESMapProps {
  chooseBuilding: Dispatch<ProjectActionTypes>;
  disabled?: boolean;
  onError: () => void;
}

const MESMap = ({ disabled, chooseBuilding, onError }: MESMapProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const readyEventHandler = async (e: any) => {
    const resp = await convertData(e.detail);
    if (!resp?.performance) {
      onError();
      return;
    }
    if (resp && resp.address.postalCode) {
      chooseBuilding({ type: 'CHANGE_BUILDING', payload: resp });
      // const data = await getCityAndCanton(resp.address.postalCode);
      // data?.canton && chooseBuilding({ type: 'SET_CANTON', payload: data.canton });
    }
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const errorEventHandler = (e: any) => {
    if (onError && e.detail.message) onError();
  };

  useEffect(() => {
    document.addEventListener('ready', readyEventHandler);
    document.addEventListener('error', errorEventHandler);

    const mesScript = document.createElement('script');
    mesScript.src = '/index.19f52e51.js';
    mesScript.type = 'module';

    mesScript.addEventListener('load', () => {
      !loaded && setLoaded(true);
    });

    document.head.appendChild(mesScript);

    loaded && document.dispatchEvent(new CustomEvent('DOMContentLoaded'));

    return () => {
      document.head.removeChild(mesScript);
      document.removeEventListener('ready', readyEventHandler);
      document.removeEventListener('error', errorEventHandler);
    };
  }, [loaded]);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const convertData = async (payload: any): Promise<Building | undefined> => {
    if (!payload) return;

    return {
      sepId: payload.featureId,
      sustainability: payload.roofSuitability === 'N/A' ? 0 : payload.roofSuitability,
      performance: payload.nominalPower,
      solarPotential: payload.Eac[0],
      address: {
        street: payload.strname1,
        houseNumber: payload.numAddOn ? `${payload.num}${payload.numAddOn}` : payload.num,
        postalCode: payload.plz4,
        municipality: payload.gdename
      },
      feedInTariff: formatFeedInTariff(+payload.feedinTariff),
      location: {
        coordinates: {
          lat: payload.lat,
          lng: payload.lon
        },
        altitude: payload.z
      }
    };
  };

  return (
    <div className="mes-container">
      <MESSearch />
      <div className={`map-container ${disabled ? 'disabled' : ''}`}>
        <div id="map" className="map">
          <div className="map-spinner-container" id="map-spinner-container">
            <div className="map-spinner"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MESMap;
