import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store';
import { Provider } from 'react-redux';
import AuthContextProvider from './context/AuthContextProvider';
import TagManager from 'react-gtm-module';

const store = configureStore();

if (process.env.REACT_APP_ENABLE_TRACKING?.toString() === 'enable') {
  const tagManagerArgs = {
    gtmId: 'GTM-5P4W2KBJ'
  };
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </Provider>
);

reportWebVitals();
