import { useTranslation } from 'react-i18next';
import './OfferRequestedNotification.scss';
import Text from '../../../components/Text/Text';
import NotificationCard from '../../../components/NotificationCard/NotificationCard';

const OfferRequestedNotification = () => {
  const { t } = useTranslation();

  return (
    <NotificationCard mainText={t('emailConfirmation.dataSentToPartners')} icon={'envelope'}>
      <Text as={'p'} category={'label'} size={'small'} className={'check-spam-text'}>
        {t('emailConfirmation.checkYourSpamOffer')}
      </Text>
    </NotificationCard>
  );
};

export default OfferRequestedNotification;
