import { useContext, useEffect, useReducer, useState } from 'react';
import './SolarSystem.scss';
import { useTranslation } from 'react-i18next';
import FinancialOfferPreview from '../../components/FinancialOfferPreview/FinancialOfferPreview';
import { Module, modules, ModuleType } from '../../types/financialOffer.types';
import Modal from '../../components/Modal/Modal';
import { useModal } from '../../hooks/useModal';
import Checklist from '../../components/Checklist/Checklist';
import Text from '../../components/Text/Text';
import FinancingPlan from '../../components/FinancingPlan/FinancingPlan';
import { emptyProject, projectReducer } from '../../store/project/project.reducer';
import { createProject, getFinancialOverview } from '../../store/project/project.action';
import {
  FinancialOverview,
  FinancialOverviewType,
  Project,
  ProjectFinancialOverview
} from '../../store/project/project.types';
import TooltipHeader from '../../components/TooltipHeader/TooltipHeader';
import SepLegend from '../../components/SepLegend/SepLegend';
import { AuthContext } from '../../context/AuthContextProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { User } from '../../store/user/types';
import useNavigation from '../../hooks/useNavigation';
import MESMap from '../../components/MES/MESMap/MESMap';
import LowSustainabilityModal from './LowSustainabilityModal/LowSustainabilityModal';
import SelectPartnerModal from './SelectPartnerModal/SelectPartnerModal';
import Loader from '../../components/Loader/Loader';

enum ModalTitle {
  LOW_SUSTAINABILITY = 'lowSustainability',
  SELECT_PARTNER = 'selectPartner'
}

const SolarSystem = () => {
  const sessionProject = JSON.parse(localStorage.getItem('project') as string);

  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const { isOpened, toggle } = useModal();
  const auth = useContext(AuthContext);
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [loading, setLoading] = useState(false);
  const [overview, setOverview] = useState<FinancialOverview | undefined>(undefined);
  const [modalTitle, setModalTitle] = useState<ModalTitle>();

  const [project, dispatch] = useReducer(projectReducer, emptyProject);

  const financigPlanDisabled =
    !project.building || !project.financingModel || !overview?.objectPrice;

  useEffect(() => {
    if (!auth?.isAuthenticated && project.building?.address?.street) {
      localStorage.setItem('project', JSON.stringify(project));
      localStorage.setItem('overview', JSON.stringify(overview));
    }

    return () => {
      const location = window.location.href;
      if (
        !location.includes(process.env.REACT_APP_KEYCLOAK_URL?.toString() as string) &&
        !location.includes('solar-system')
      ) {
        clearLocalStorage();
      }
    };
  }, [project, overview]);

  useEffect(() => {
    if (!!sessionProject && auth?.isAuthenticated) {
      createUserProject(sessionProject);
    }
  }, [user.uid]);

  useEffect(() => {
    project.building && getOverview();
  }, [project.modules?.length, project.building?.sepId, project.building?.performance]);

  const getOverview = () => {
    const inputs = {
      type: FinancialOverviewType.INDICATIVE,
      deposit: project.financingModel?.deposit,
      performance: project.building?.performance,
      modules: project.modules?.map((m) => {
        return {
          type: m.type,
          value: m.value
        } as Pick<Module, 'type' | 'value'>;
      }),
      solarPotential: project.building?.solarPotential,
      altitude: project.building?.location?.altitude,
      sustainability: project.building?.sustainability
    };
    getFinancialOverview(inputs).then((resp) => {
      const feedIn = resp?.feedInTariff || project.building?.feedInTariff || '0';
      const performance = project.building?.performance ?? '';
      setOverview({ ...resp, feedInTariff: feedIn, performance } as ProjectFinancialOverview);
    });
  };

  const checkModule = (module: Module) => {
    project.modules?.find((m: Partial<Module>) => m.id === module.id)
      ? dispatch({ type: 'REMOVE_MODULE', payload: module })
      : dispatch({ type: 'ADD_MODULE', payload: module });
  };

  const changeDeposit = (newDeposit: number) => {
    dispatch({ type: 'CHANGE_DEPOSIT', payload: newDeposit });
  };

  const changeDuration = (newDuration: number) => {
    dispatch({ type: 'CHANGE_DURATION', payload: newDuration });
  };

  const createUserProject = async (project: Project) => {
    setLoading(true);
    let loanAmount;
    if (localStorage.getItem('overview')) {
      loanAmount = JSON.parse(localStorage.getItem('overview') as string)?.loan;
    } else {
      loanAmount = overview?.loan.toString() as string;
    }
    const nplProject = {
      userId: user.uid,
      loanAmount,
      loanTerm: (project.financingModel?.duration as number) * 12,
      productTypes: project?.modules?.map((module) => module?.type) as ModuleType[]
    };

    user.uid &&
      (await createProject({
        project: nplProject,
        projectDto: project as Project
      }).then((data) => {
        if (data) {
          if (sessionProject || localStorage.getItem('overview')) {
            clearLocalStorage();
          }
          goTo(`/my-profile/projects`, {
            replace: true,
            state: { offerRequestedNotification: true }
          });
        }
      }));
    setLoading(false);
  };

  const clearLocalStorage = () => {
    if (sessionProject || localStorage.getItem('overview')) {
      localStorage.removeItem('project');
      localStorage.removeItem('overview');
    }
  };

  const renderModalContent = () => {
    switch (modalTitle) {
      case ModalTitle.LOW_SUSTAINABILITY:
        return <LowSustainabilityModal />;
      case ModalTitle.SELECT_PARTNER:
        return (
          <SelectPartnerModal
            toggle={toggle}
            dispatch={dispatch}
            project={project}
            createProject={handleProjectCreation}
          />
        );
      default:
        return <></>;
    }
  };

  const openModal = (modalTitle: ModalTitle) => {
    setModalTitle(modalTitle);
    toggle();
  };

  const closeModal = () => {
    setModalTitle(undefined);
    toggle();
  };

  const handleProjectCreation = () => {
    !auth?.isAuthenticated
      ? auth?.keycloak.register({ redirectUri: window.location.href })
      : createUserProject(project as Project);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="solar-system-header">
            <Text as="h2" category="display" variety={2} className="solar-system-heading">
              {t('solarSystemHeading')}
            </Text>
            <Text as="p" category="body" size="large">
              {t('solarSystem.headingDescription')}
            </Text>
          </div>
          <main className="solar-system-wrapper">
            <div className="solar-system">
              <Text as="h3" category="headline" size="large">
                {t('searchAddress')}
              </Text>
              <MESMap
                chooseBuilding={dispatch}
                onError={() => {
                  dispatch({ type: 'REMOVE_BUILDING' });
                  openModal(ModalTitle.LOW_SUSTAINABILITY);
                }}
              />
              <SepLegend />
              <div className="modules-wrapper">
                <div className="solar-system-modules">
                  <TooltipHeader
                    heading={t('module.label')}
                    tooltip={{ text: t('module.tooltip') }}
                  />
                  <Text as="p" category="body" size="large" className="solar-system-modules-text">
                    {t('module.selectAdditionalModules')}
                  </Text>
                  <Checklist
                    items={modules.map((m) => {
                      return {
                        ...m,
                        label: t(`${m.label}`)
                      };
                    })}
                    checked={project?.modules as Module[]}
                    disabled={!project.building}
                    onCheck={checkModule}
                  />
                </div>
                {/* <div className="solar-system-modules">
                  <TooltipHeader
                    heading={t('module.labelPowerFeed')}
                    tooltip={{ text: t('module.tooltipPowerFeed') }}
                  />
                  <Text as="p" category="body" size="large" className="solar-system-modules-text">
                    {t('module.selectPowerFeedModule')}
                  </Text>
                  <Checklist
                    isPrice
                    items={[investerraModule].map((m) => {
                      return { ...m, label: t(`${m.label}`) };
                    })}
                    checked={project.modules as Module[]}
                    disabled={!project.building}
                    onCheck={checkModule}
                  />
                </div> */}
              </div>
            </div>
            <div className={'solar-system-sidebar'}>
              <FinancialOfferPreview
                overview={overview}
                disabled={!project.building}
                modules={project.modules as Module[]}
                status="indicative"
              />
              <FinancingPlan
                showRate={!!project.building?.address}
                amount={overview?.objectPrice || 0}
                baseFee={overview?.fee}
                deposit={project.financingModel?.deposit}
                duration={project.financingModel?.duration}
                performance={project.building?.performance}
                subsidy={overview?.subsidy}
                modules={project.modules as Module[]}
                setDeposit={changeDeposit}
                setDuration={changeDuration}
                onSubmit={handleProjectCreation}
                disable={financigPlanDisabled}
                status="indicative"
                onDepositChange={getOverview}
              />
              {modalTitle && (
                <Modal
                  title={t(`solarSystem.modalTitle.${modalTitle}`)}
                  isOpened={isOpened}
                  close={closeModal}>
                  {renderModalContent()}
                </Modal>
              )}
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default SolarSystem;
